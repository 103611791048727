import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";

import { CHECKOUT_DIALOG_A_ID, CHECKOUT_DIALOG_B_ID, CHECKOUT_DIALOG_C_ID, CHECKOUT_DIALOG_CONTROL_ID } from "common/constants";
import * as analytics from "js/analytics";
import { getStaticUrl } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { User } from "js/core/models/user";
import { getExperiments } from "js/core/services/experiments";
import db from "js/db";
import { RemoveSplashScreen } from "js/editor/SplashScreen";
import { app } from "js/namespaces";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import CheckoutDialog from "js/react/views/UserOptions/Billing/CheckoutDialog";
import CheckoutDialogA from "js/react/views/UserOptions/Billing/CheckoutDialogA";
import CheckoutDialogB from "js/react/views/UserOptions/Billing/CheckoutDialogB";
import CheckoutDialogC from "js/react/views/UserOptions/Billing/CheckoutDialogC";
import CheckoutSignupB from "js/react/views/UserOptions/Billing/CheckoutSignupB";
import CreateWorkspaceDialogA from "js/react/views/UserOptions/Billing/CreateWorkspaceDialogA";
import CreateWorkspaceDialogB from "js/react/views/UserOptions/Billing/CreateWorkspaceDialogB";
import EnterpriseInviteDialog from "js/react/views/UserOptions/Billing/EnterpriseInviteDialog";
import { trackActivity } from "js/core/utilities/utilities";

import "css/billing.scss";
import "css/components.scss";
import "css/controls.scss";
import "css/dialogs.scss";

const logger = getLogger(LogGroup.CHECKOUT);

const Container = styled.div.attrs(({ withImageBackground }) => ({
    style: {
        background: withImageBackground ? `url(${getStaticUrl("/images/onboarding/slide-grid.jpg")}) no-repeat top right` : "white",
        backgroundSize: withImageBackground ? "962px 1198px" : "unset"
    }
}))`
    height: 100vh;
`;

function CheckoutDialogDistribution(props) {
    const { isSignUpB, defaultBillingInterval, checkoutModalId } = props;

    if (isSignUpB) {
        return <CheckoutSignupB {...props} />;
    }

    // We are planning on tracking only the year plan for now, but if a user wants to switch to monthly, we will show them the old checkout dialog
    if (defaultBillingInterval === "month") {
        return (<CheckoutDialog {...({ ...props, checkoutModalId: null })} />);
    }

    switch (checkoutModalId) {
        case CHECKOUT_DIALOG_A_ID:
            return (<CheckoutDialogA {...props} />);
        case CHECKOUT_DIALOG_B_ID:
            return (<CheckoutDialogB {...props} />);
        case CHECKOUT_DIALOG_C_ID:
            return (<CheckoutDialogC {...props} />);
        case CHECKOUT_DIALOG_CONTROL_ID:
        default:
            return (<CheckoutDialog {...props} />);
    }
}

function CreateWorkspaceDialogDistribution(props) {
    const { isSignUpB } = props;

    if (isSignUpB) {
        return <CreateWorkspaceDialogB {...props} />;
    }

    return <CreateWorkspaceDialogA {...props} />;
}

class Checkout extends React.Component {
    state = {
        loading: true,
        isSignUpB: false
    };

    async componentDidMount() {
        const { firebaseUser, plan } = this.props;

        try {
            await db.updateCurrentUser(firebaseUser);

            let {
                sign_up_b: { enabled: isSignUpB },
                onboarding_plan_confirm: { enabled: showPlan },
            } = await getExperiments(["sign_up_b", "onboarding_plan_confirm"]);

            if (plan === "enterprise") {
                // Special case for enterprise, force use control UI
                isSignUpB = false;
            }

            app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });

            ds.checkoutSetup();

            await app.user.load();

            await analytics.identify(firebaseUser);

            RemoveSplashScreen(true);

            this.setState({
                loading: false,
                isSignUpB,
                checkoutModalId: app.user.get("checkoutModalId")
            });

            if (plan !== "enterprise" && showPlan && !app.user.get("didConfirmPlan") && !app.user.hasSubscription() && !app.user.get("hasTakenTeamTrial")) {
                // Special case to make Cameron's life easier
                trackActivity("SignupFlow", "NavForward", null, null, {
                    experiment_id: "FD081BDC35F0EDCADAA156ABA8CB55F6",
                    step_number: isSignUpB ? 7 : 3,
                    step_name: "Choose Plan",
                    plan_selected: plan ?? "pro",
                    experiment_group_assignment: isSignUpB ? "B" : "A",
                    object: "button",
                    object_label: plan === "team" ? "Team" : "Pro",
                    action: "clicked",
                    workspace_id: "all"
                }, { audit: true });
            }
        } catch (err) {
            logger.error(err, "load failed", { uid: firebaseUser.uid, plan });

            ShowWarningDialog({
                title: "An error occurred while loading user data",
            });
        }
    }

    render() {
        const { plan, billingInterval, displayName, priceId, quantity, enterpriseInviteId, closeDialog, hideBackdrop = true } = this.props;
        const { loading, isSignUpB, checkoutModalId } = this.state;

        if (loading) {
            return <div></div>;
        }

        const checkoutFormProps = {
            checkoutModalId,
            defaultBillingInterval: billingInterval,
            productDisplayName: displayName,
            quantity,
            priceId,
            closeDialog,
            hideBackdrop,
            isSignUpB,
            enterpriseInviteId
        };

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <Container withImageBackground={!isSignUpB}>
                    {(plan ?? "pro") === "pro" && <CheckoutDialogDistribution {...checkoutFormProps} />}
                    {plan === "team" && <CreateWorkspaceDialogDistribution {...checkoutFormProps} />}
                    {plan === "enterprise" && <EnterpriseInviteDialog {...checkoutFormProps} />}
                </Container>
            </MuiThemeProvider>
        );
    }
}

export default withFirebaseUser(Checkout);
