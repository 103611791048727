import { PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, PaymentMethodResult } from "@stripe/stripe-js";
import React, { Component } from "react";
import styled from "styled-components";

import stripeProducts from "common/stripeProducts";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { handlePaymentIntentResult, handleStripeErrors } from "js/core/services/stripe";
import Button from "js/react/components/Button2";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20, Gap30 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { MinimalBillingAddressForm } from "js/react/views/UserOptions/Billing/MinimalBillingAddressForm";
import withElements from "js/react/views/UserOptions/Billing/withElements";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";
import PromoField from "js/react/views/UserOptions/components/PromoField";

import "css/billing.scss";

const logger = getLogger(LogGroup.BILLING);

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const AddressContainer = styled.div`    
    margin-top: 5px;
    margin-bottom: 12px;
    gap: 12px;
`;

const PaymentElementContainer = styled.div`
    width: 100%;
`;

const ErrorMessageContainer = styled.div`
    color: red;
    margin-top: 10px;
    text-align: left;
`;

// This needs cleaning up, for now it's the way the PaymentFormB is used by other components
interface PaymentFormProps {
    stripe: Stripe,
    elements: StripeElements,

    priceId: string;
    customerType: "individual" | "team";

    seatCount?: number;
    teamName?: string;
    members?: { id?: string, email: string, role: string, isDisabled?: boolean }[];
    message?: string;
    organizationId?: string;

    isReactivating?: boolean;
    hasTakenTrial?: boolean;

    showTaxIdForm?: boolean;
    showTitle?: boolean;

    onSuccess: (paymentResult: {
        subscription_id: string;
        subscription_status: string;
        status: "requires_action" | "requires_setup_action" | "succeeded" | "requires_payment_method";
        payment_method?: string;
        client_secret?: string;
        orgId?: string;
        org?: Object,
        defaultTeam?: Object,
        sharedTheme?: Object
    }) => void;
    onFailure: (error?: Error) => void;
    onBeforeSubmit: () => boolean | void;
    onPromoChanged: (promo: string) => void;
    onTaxCalculated: (taxAmount: number) => void;

    onCancel?: () => void;
    cancelLabel?: string;

    gapElement?: React.ReactNode;
    titleStyle?: React.CSSProperties;
    paymentDetails?: React.ReactNode | string;
    submitLabel?: string;
    submitButtonStyle?: React.CSSProperties;
    buttonsContainerStyle?: React.CSSProperties;
    canSubmit?: boolean;
    errorMessage?: string;
    isSignUpB?: boolean;
}

interface PaymentFormState {
    promotionCode: string | null;
    submitting: boolean;
    priceId: string;
    productId: string;
    productName: string;
    productPrice: number;
    billingInterval: "month" | "year";
    billingAddress: {
        country: string;
        postal_code: string;
    };
    isBillingAddressValid: boolean;
    error: string | null;
    taxIdType: string | null;
    taxId: string;
    isTaxIdValid: boolean;
}

const appearance = {
    labels: "floating",
    variables: {
        letterSpacing: "-0.18px",
        border: "none",
        fontFamily: "Source Sans Pro, sans-serif",
        borderRadius: "0px",
        colorDanger: "#fa755a",
        colorTextPlaceholder: "#666"
    },
    rules: {
        ".Label--resting": {
            color: "#666",
            fontFamily: "Source Sans Pro",
            fontSize: "16px"
        },
        ".Input": {
            border: "none",
            boxShadow: "none",
            borderBottom: "1px solid #ccc",
            transition: "none",
            backgroundColor: "transparent",
            paddingLeft: "0px",
            paddingBottom: "7px"
        },
        ".Input:focus": {
            boxShadow: "none",
            borderBottom: "1px solid #11a9e2",
        },
        ".Input:focus:hover": {
            borderBottom: "1px solid #11a9e2",
        },
        ".Input--invalid": {
            border: "none",
            iconColor: "#fa755a",
            boxShadow: "none",
            borderBottom: "1px solid #fa755a",
        },
        ".Input--invalid:hover": {
            border: "none",
            borderBottom: "1px solid #fa755a",
        },
        ".Input:hover": {
            boxShadow: "none",
            borderBottom: "1px solid #11a9e2",
        },
        ".TermsText": {
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "145%",
            letterSpacing: "-0.28px",
            color: "#666",
            margin: "0px",
            paddingTop: "0px"
        }
    }
};

class PaymentFormB extends Component<PaymentFormProps, PaymentFormState> {
    constructor(props) {
        super(props);

        const { priceId, customerType } = props;
        if (!priceId) {
            throw new Error("priceId is required");
        }
        if (!customerType) {
            throw new Error("customerType is required");
        }

        this.state = {
            promotionCode: null,
            submitting: false,
            priceId,
            ...this.getProductState(priceId),
            billingAddress: {
                country: "",
                postal_code: ""
            },
            isBillingAddressValid: false,
            error: null,
            taxIdType: null,
            taxId: "",
            isTaxIdValid: true
        };
    }

    componentDidUpdate(prevProps: Readonly<PaymentFormProps>, prevState: Readonly<PaymentFormState>): void {
        if (prevState.promotionCode !== this.state.promotionCode) {
            this.handleFetchPromo(this.state.promotionCode);
        }

        if (prevProps.priceId !== this.props.priceId) {
            this.setState({
                priceId: this.props.priceId,
                ...this.getProductState(this.props.priceId)
            });
        }

        if (
            this.state.isBillingAddressValid && this.state.isTaxIdValid && (
                prevState.billingAddress.country !== this.state.billingAddress.country ||
                prevState.billingAddress.postal_code !== this.state.billingAddress.postal_code ||
                prevState.productId !== this.state.productId ||
                prevState.productPrice !== this.state.productPrice ||
                prevState.taxId !== this.state.taxId ||
                prevState.taxIdType !== this.state.taxIdType
            )
        ) {
            this.calculateTaxes();
        }
    }

    getProductState(priceId: string) {
        let productId: string, productName: string, productPrice: number, billingInterval: "month" | "year";
        for (const product of Object.values(stripeProducts)) {
            for (const plan of product.plans) {
                if ("planId" in plan && plan.planId === priceId) {
                    productId = product.productId;
                    productName = product.productName;
                    productPrice = plan.amount;
                    billingInterval = plan.interval;
                }
            }
        }

        if (!productId) {
            throw new Error(`Price ${priceId} not found`);
        }

        return {
            productId,
            productName,
            productPrice,
            billingInterval
        };
    }

    calculateTaxes = async () => {
        const { onTaxCalculated } = this.props;
        const {
            productPrice,
            productId,
            billingAddress,
            taxId
        } = this.state;

        try {
            const { taxAmount } = await Api.taxes.post({
                amount: productPrice,
                stripeProductId: productId,
                countryCode: billingAddress.country,
                postalCode: billingAddress.postal_code,
                taxId
            });
            onTaxCalculated(taxAmount);
        } catch (err) {
            logger.error(err, "[PaymentFormB] failed to calculate tax");
            onTaxCalculated(0);
        }
    }

    handleSubmit = async () => {
        const {
            seatCount,
            customerType,
            teamName,
            members,
            message,
            onSuccess,
            organizationId,
            isReactivating,
            stripe,
            elements,
            onFailure,
            onBeforeSubmit
        } = this.props;
        const {
            promotionCode,
            submitting,
            priceId,
            billingAddress,
            taxId,
            taxIdType
        } = this.state;

        if (submitting) {
            return;
        }

        if (onBeforeSubmit() === false) {
            return;
        }

        this.setState({ submitting: true });

        const { error: submitError } = await elements.submit();
        if (submitError) {
            this.setState({ error: submitError.message, submitting: false });
            onFailure(new Error(submitError.message));
            return;
        }

        try {
            const { paymentMethod } = await handleStripeErrors<PaymentMethodResult>(() => stripe.createPaymentMethod({
                elements,
                params: {
                    billing_details: {
                        address: {
                            country: billingAddress.country,
                            postal_code: billingAddress.postal_code
                        }
                    }
                }
            }));

            const apiResponse = await Api.subscriptions.post({
                payment_method_id: paymentMethod.id,
                price_id: priceId,
                quantity: seatCount,
                customer_type: customerType,
                team_name: teamName,
                organization_id: organizationId,
                promotion_code: promotionCode,
                tax_id: taxId,
                tax_id_type: taxIdType
            });

            try {
                await handlePaymentIntentResult(stripe, apiResponse);
            } catch (err) {
                // Aborting the subscription creation
                await Api.subscriptions.put({
                    customerType,
                    orgId: apiResponse.org?.id,
                    type: "abort_create",
                    isReactivating
                });

                throw err;
            }

            // Finalizing the subscription creation
            const { body: updatedPaymentResult } = await Api.subscriptions.put({
                customerType,
                members: members ?? apiResponse.members,
                message,
                orgId: apiResponse.org?.id,
                type: "finalize",
                isReactivating
            });

            onSuccess({ ...apiResponse, ...updatedPaymentResult, orgId: apiResponse.org?.id });
        } catch (err) {
            logger.error(err, "[PaymentFormB] Error creating subscription");

            ShowErrorDialog({
                error: "An error occurred while creating this transaction",
                message: (
                    <>
                        <p style={{ marginTop: 0 }}>
                            <strong>Error:</strong> {err.message}
                        </p>
                        <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                    </>
                )
            });

            onFailure(err);

            this.setState({ submitting: false });
        }
    };

    handleFetchPromo = async code => {
        const { hasTakenTrial } = this.props;
        const { productName, productPrice } = this.state;

        const promo = await Api.promos.get({ code });

        // check restrictions
        if (promo.appliesTo && !promo.appliesTo.includes(productName)) {
            throw new Error(`This coupon can't be applied to a ${productName} plan`);
        }

        if (promo.minimumAmount && productPrice < promo.minimumAmount) {
            throw new Error(`This promo can't be applied to bills of less than $${promo.minimumAmount / 100}`);
        }

        if (promo.firstTimeTransaction && hasTakenTrial) {
            throw new Error("This promo is only for new users");
        }

        if (promo.error) {
            throw new Error(promo.error);
        }

        this.setState({ promotionCode: promo.id });

        return promo;
    }

    handleClearPromo = () => {
        this.setState({ promotionCode: null });
    }

    handleBillingAddressFormChange = (
        {
            address,
            isAddressValid,
            taxId,
            taxIdType,
            isTaxIdValid
        }:
            {
                address: { country: string, postal_code: string },
                isAddressValid: boolean,
                taxId: string,
                taxIdType: string | null,
                isTaxIdValid: boolean
            }
    ) => {
        this.setState({ billingAddress: address, isBillingAddressValid: isAddressValid, taxId, taxIdType, isTaxIdValid });
    }

    render() {
        const {
            canSubmit = true,
            submitButtonStyle = {},
            submitLabel,
            onCancel,
            cancelLabel = "Cancel",
            paymentDetails = null,
            showTitle = true,
            titleStyle = {},
            buttonsContainerStyle = {},
            showTaxIdForm = false,
            errorMessage = null,
            isSignUpB = false
        } = this.props;
        const {
            error,
            submitting,
            productName,
            billingInterval,
            billingAddress,
            taxId,
            isTaxIdValid,
            isBillingAddressValid
        } = this.state;

        return (<div id="checkout_form">
            {showTitle && <div style={titleStyle}>Payment details</div>}
            <PaymentElementContainer>
                <PaymentElement
                    onChange={(event: any) => {
                        this.setState({
                            error: event.error?.message ?? null
                        });
                    }}
                    options={{
                        fields: {
                            billingDetails: {
                                address: {
                                    country: "never",
                                    postalCode: "never"
                                }
                            }
                        }
                    }}
                />
                {error && <div className="card-error">{error}</div>}
            </PaymentElementContainer>
            <Gap20 />
            <AddressContainer>
                <MinimalBillingAddressForm
                    initialAddress={billingAddress}
                    initialTaxId={taxId}
                    onChange={this.handleBillingAddressFormChange}
                    disabled={submitting}
                    showTaxIdForm={showTaxIdForm}
                    isSignUpB={isSignUpB}
                />
            </AddressContainer>
            <PromoField
                fetchPromo={this.handleFetchPromo}
                fetchKey={`${productName}-${billingInterval}`}
                onClear={this.handleClearPromo}
                isSignUpB={isSignUpB}
            />

            <ButtonsContainer style={buttonsContainerStyle}>
                <Button
                    style={submitButtonStyle}
                    disabled={!canSubmit || submitting || !isBillingAddressValid || !isTaxIdValid}
                    onClick={this.handleSubmit}
                    label={submitLabel}
                    id="pay"
                />
                {/* @ts-ignore */}
                {onCancel && <FlexBox center>
                    <Button
                        style={{
                            background: "transparent",
                            color: "#11a9e2",
                            marginTop: "10px",
                        }}
                        disabled={submitting}
                        label={cancelLabel}
                        onClick={onCancel}
                    />
                </FlexBox>}
            </ButtonsContainer>

            {paymentDetails &&
                <>
                    <Gap30 />
                    {paymentDetails}
                </>
            }
            {errorMessage && <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>}
        </div>);
    }
}

// @ts-ignore
export default withStripe(withElements(PaymentFormB, { appearance }));
