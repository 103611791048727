import {
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { v4 as uuid } from "uuid";

import { browserHistory } from "js/react/history";
import { AnalyticsRolesAndLicensesMap, BEAUTIFUL_WORKSPACE_ACTION, BEAUTIFUL_WORKSPACE_ID, TEAM_USER_LICENSE_STATUS, TEAM_USER_ROLES, WorkspaceAction } from "common/constants";
import { isPPTAddin } from "js/config";
import Api from "js/core/api";
import { User } from "js/core/models/user";
import getLocalStorage from "js/core/utilities/localStorage";
import { emailRegex } from "js/core/utilities/regex";
import { trackActivity } from "js/core/utilities/utilities";
import db from "js/db";
import { app } from "js/namespaces";
import AddTeamMembers from "js/react/components/AddTeamMembers";
import BeautifulLogo from "js/react/components/BeautifulLogo";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap30 } from "js/react/components/Gap";
import Icon from "js/react/components/Icon";
import ProgressBar from "js/react/components/ProgressBar";
import Spinner from "js/react/components/Spinner";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import PaymentFormB from "js/react/views/UserOptions/Billing/PaymentFormB";
import SuccessfulPurchase from "js/react/views/UserOptions/Billing/SuccessfulPurchase";
import BillingDataService from "js/react/views/UserOptions/dataservices/billingDataService";

import "css/billing.scss";
import { TermsOfUseAndRefundPolicy } from "./CheckoutDialog";

const localStorage = getLocalStorage();

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const HeaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;

const HeaderBackdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 100px;
    bottom: 0;
    background-color: white;
    z-index: -1;
`;

const BeautifulLogoContainer = styled.div`
    position: absolute;
    top: 23px;
    left: 128px;

    @media (max-width: 768px) {
        left: 10px;
    }
`;

const ProgressBarContainer = styled.div`
    margin-left: 128px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 256px);
    height: 3px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
    margin-top: 68px;

    @media (max-width: 768px) {
        margin-left: 10px;
        width: calc(100% - 20px);
    }
`;

const StyleDialogTitle = styled.div`
    margin-bottom: 20px;
    div {
            display: flex;
            align-content: center;
            text-align: center;
            color: #222;
            font-family: "Source Sans Pro";
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: -0.5px;
        i {
            color: #11a9e2;
            margin-right: 15px;
            margin-top: 2px;
        }
    }
`;

const ScrollContainer = styled.div`
    overflow-y: auto;
    width: 100%;
    max-height: calc(100vh - 110px);
    margin-top: 110px;
`;

const ContentAlignerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    gap: 16px;
    max-width: 1250px;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 1180px) {
        grid-template-columns: repeat(20, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const WorkspaceSettingsContainer = styled.div`
    grid-column: 3 / span 11;

    @media (max-width: 1180px) {
        grid-column: 3 / span 16;
    }

    @media (max-width: 768px) {
        grid-column: 1 / -1;
    }
`;

const PaymentDetailsContainer = styled.div`
    grid-column: 16 / span 11;

    @media (max-width: 1180px) {
        grid-column: 3 / span 16;
    }

    @media (max-width: 768px) {
        grid-column: 1 / -1;
    }
`;

const TeamNameContainer = styled.div`
    margin-bottom: 30px;
    width: 100%;
`;

const SectionTitle = styled.div`
    color:#222;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 18px;
`;

const TeamNameField = styled(TextField)`
    width: 90%;
    label {
        color: ${props => (props.error ? "red" : "inherit")};
    }
`;

const StyledSelect = styled(Select)`
    .MuiSelect-select {
        padding-left: 0px;
        font-weight: 400;
        color: rgb(48, 49, 61);
        width: 400px;
        font-size: 16px;
    }
`;

const urlParams = new URLSearchParams(window.location.search);
const skipTrial = urlParams.has("t");

class CreateWorkspaceDialogB extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trialExpirationDate: null,
            teamName: "",
            members: [],
            successfulPurchase: false,
            isSubmitting: false,
            hasTakenTeamTrial: false,
            errorCompanyNameMissing: false,
            taxAmountPerSeat: null,
            billingInterval: props.defaultBillingInterval ?? "year",
            seatCount: props.quantity ?? 1,
        };
    }

    async componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const googleAuthSuccess = urlParams.get("google_auth_success");

        if (googleAuthSuccess === "true") {
            const userInfoSnap = await db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}/userInfo`).once("value");
            const userInfo = userInfoSnap.val();

            if (!userInfo.complete) {
                window.location.href = "/";
                return;
            }
        }

        // fetch pricing from Stripe
        let prices;
        if (this.props.priceId && this.props.priceId.includes(" ")) {
            const priceIds = this.props.priceId.split(" ");

            const priceData = [
                await Api.prices.get({ id: priceIds[0] }),
                await Api.prices.get({ id: priceIds[1] })
            ];

            const month = priceData.find(p => p.recurring.interval === "month");
            const year = priceData.find(p => p.recurring.interval === "year");

            if (!month || !year) {
                throw new Error("expected monthly and yearly prices'");
            }

            prices = {
                month,
                year,
            };
        } else if (this.props.priceId) {
            prices = {
                year: await Api.prices.get({ id: this.props.priceId })
            };
        } else {
            const priceIds = BillingDataService.getDefaultPriceIds("team");
            prices = {
                month: await Api.prices.get({ id: priceIds.month }),
                year: await Api.prices.get({ id: priceIds.year })
            };
        }

        const hasTakenTeamTrial = skipTrial || app.user.get("hasTakenTeamTrial") || false;
        this.setState({
            prices,
            productDisplayName: this.props.productDisplayName ??
                prices.year.nickname.substring(0, prices.year.nickname.indexOf("-")),
            hasTakenTeamTrial,
            trialExpirationDate: moment().add(14, "day"),
            members: [{
                id: app.user.id,
                email: app.user.getEmail(),
                role: TEAM_USER_ROLES.OWNER,
                isDisabled: true
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.LIBRARIAN,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }, {
                id: uuid(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED,
            }]
        });

        const billingInterval = this.state.billingInterval;
        const price = prices[billingInterval];

        const params = new URLSearchParams(window.location.search);
        const testId = params.get("ex");

        const createdProps = {
            billing_term: billingInterval,
            price_id: price.id,
            plan_display_name: this.state.productDisplayName,
            min_quantity: this.props.quantity,
            test_id: testId,
        };
        trackActivity("Organization", "BillingIntentStart", testId, null, createdProps, { audit: false });
    }

    get amount() {
        const { billingInterval, promo, prices, seatCount } = this.state;
        const price = prices[billingInterval];

        let subtotal;
        if (price.billing_scheme === "tiered") {
            if (price.tiers_mode === "graduated") {
                let seats = seatCount;
                subtotal = 0;
                let prevTierUpTo = 0;
                for (const tier of price.tiers) {
                    if (seats === 0) {
                        break;
                    }
                    if (tier.up_to === null) {
                        // final tier
                        subtotal += tier.unit_amount * seats;
                        seats = 0;
                    } else {
                        const maxSeatsInTier = tier.up_to - prevTierUpTo;
                        const tierSeats = maxSeatsInTier - Math.max(0, maxSeatsInTier - seats);
                        seats -= tierSeats;
                        subtotal += tier.unit_amount * tierSeats;
                        subtotal += tier.flat_amount;
                        prevTierUpTo = tier.up_to;
                    }
                }
            } else {
                throw new Error("unsupported tiers_mode: " + price.tiers_mode);
            }
        } else {
            subtotal = price.unit_amount * seatCount;
        }

        const dollars = subtotal / 100;
        return BillingDataService.applyCoupon(dollars, promo);
    }

    get numTieredSeats() {
        const { billingInterval, prices, seatCount } = this.state;
        const price = prices[billingInterval];

        let seats = seatCount;
        let numSeatsFromTiers = 0;
        for (const tier of price.tiers) {
            if (seats === 0) {
                break;
            }
            seats = Math.max(0, seatCount - tier.up_to);
            numSeatsFromTiers = tier.up_to;
        }

        return Math.max(seats, numSeatsFromTiers);
    }

    handleChangeTeamName = event => {
        this.setState({ teamName: event.target.value, errorCompanyNameMissing: false });
    }

    handleMemberUpdate = (id, propKey, propValue) => {
        const { quantity } = this.props;

        this.setState(prevState => {
            const members = prevState.members.map(member => member.id === id ? { ...member, [propKey]: propValue } : { ...member });

            // The seat count equals the number of members that have their email filled out AND do not have the Free Member role
            let seatCount = members.filter(member => member.email.length > 1 && member.role !== TEAM_USER_ROLES.MEMBER).length;
            if (quantity) {
                seatCount = Math.max(seatCount, quantity);
            }

            return {
                ...prevState,
                members,
                seatCount
            };
        });
    };

    handleAddMember = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                members: prevState.members.concat({
                    id: uuid(),
                    email: "",
                    role: TEAM_USER_ROLES.MEMBER_LICENSED
                })
            };
        });
    };

    handleRemoveMember = id => {
        this.setState(prevState => {
            const members = prevState.members.filter(member => member.id !== id);

            //The seat count equals the number of members that have their email filled out AND do not have the Free Member role
            let seatCount = members.filter(member => member.email.length > 1 && member.role !== TEAM_USER_ROLES.MEMBER).length;
            if (this.props.quantity) {
                seatCount = Math.max(seatCount, this.props.quantity);
            }

            return {
                ...prevState,
                members,
                seatCount
            };
        });
    };

    handleSuccess = async response => {
        const { members, seatCount, teamName, trialExpirationDate, hasTakenTeamTrial, billingInterval, prices } = this.state;
        const { orgId, sharedTheme } = response;
        const price = prices[billingInterval];

        const createdProps = {
            workspace_id: orgId,
            organization_name: teamName,
            price_id: price.id,
            plan_display_name: this.state.productDisplayName,
            min_quantity: this.props.quantity
        };
        trackActivity("Organization", "Created", null, null, createdProps, { audit: true });
        await Api.klaviyoTrack.post({
            eventName: "Organization:Created",
            createdProps,
        });

        const params = new URLSearchParams(window.location.search);
        const testId = params.get("ex");

        const billingProps = {
            workspace_id: orgId,
            seats_added: seatCount,
            type: "first_purchase",
            seat_recipients: members.map(m => m.email),
            billing_term: billingInterval,
            total_seats: seatCount,
            promo_code: this.state.promo?.code,
            price_id: price.id,
            plan_display_name: this.state.productDisplayName,
            min_quantity: this.props.quantity,
            test_id: testId
        };
        trackActivity("Organization", "BillingIntentComplete", testId, null, billingProps, { audit: true });
        await Api.klaviyoTrack.post({
            eventName: "Organization:BillingIntentComplete",
            billingProps,
        });

        const inviteProps = {
            workspace_id: orgId,
            recipients: members.map(m => m.email),
            user_roles: members.map(m => AnalyticsRolesAndLicensesMap[m.role]),
            user_license: members.map(m => {
                if (m.role === TEAM_USER_ROLES.MEMBER) {
                    return TEAM_USER_LICENSE_STATUS.FREE;
                }
                return TEAM_USER_LICENSE_STATUS.TEAM_PRO;
            })
        };
        trackActivity("Organization", "InviteSent", null, null, inviteProps, { audit: true });

        const themeProps = {
            workspace_id: orgId,
            theme_id: sharedTheme.id
        };
        trackActivity("OrgTheme", "Created", null, null, themeProps, { audit: true });

        trackActivity("SignupFlow", "NavForward", null, null, {
            experiment_id: "FD081BDC35F0EDCADAA156ABA8CB55F6",
            step_number: 8,
            step_name: "Checkout",
            plan_selected: "team",
            experiment_group_assignment: "B",
            object: "button",
            object_label: "Start free trial",
            action: "clicked",
            workspace_id: "all"
        }, { audit: true });

        // prevent tour for team owner, appcues will serve its own
        app.user.update({
            hasTakenTour: true,
            // We do not want to preset <ChoosePlan> again for the team owner as they already confirmed the plan
            didConfirmPlan: true
        });

        const dialog = ShowDialog(SuccessfulPurchase, {
            productDisplayName: this.state.productDisplayName,
            callToActionText: "Continue",
            message: "You've signed up for the Beautiful.ai Team Plan!",
            showTrial: !hasTakenTeamTrial,
            trialExpirationDate: trialExpirationDate.format("MMMM Do YYYY"),
            onCallToActionClick: () => {
                if (isPPTAddin) {
                    this.props.closeDialog(true);
                } else {
                    browserHistory.push("/");
                    dialog.close();
                }
            }
        });

        // switch to the new org
        localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, orgId);
        localStorage.setItem(BEAUTIFUL_WORKSPACE_ACTION, WorkspaceAction.CREATED);

        trackActivity("Organization", "Joined", null, null, { workspace_id: orgId });
        this.setState({ successfulPurchase: true });
    };

    handleBeforeCharge = () => {
        const { teamName } = this.state;
        if (teamName.length === 0) {
            this.setState({ errorCompanyNameMissing: true });
            return false;
        }

        this.setState({ isSubmitting: true });
        return true;
    }

    handleFailure = () => {
        this.setState({ isSubmitting: false });
    }

    handlePromoChanged = promo => {
        this.setState({ promo });
    }

    handleTaxCalculated = taxAmount => {
        this.setState({ taxAmountPerSeat: taxAmount });
    }

    render() {
        const {
            successfulPurchase,
            billingInterval,
            isSubmitting,
            teamName,
            members,
            message,
            seatCount,
            hasTakenTeamTrial,
            trialExpirationDate,
            errorCompanyNameMissing,
            prices,
            taxAmountPerSeat
        } = this.state;

        if (!this.state.prices) {
            return null;
        }

        const taxPerSeat = (taxAmountPerSeat || 0) / 100;
        const pricePerSeatWithTax = (this.amount / seatCount).toFixed(2) + (taxPerSeat ? ` plus tax` : "");
        const totalPriceWithTax = (this.amount + taxPerSeat * seatCount).toFixed(2);

        const price = prices[billingInterval];

        const priceId = price.id;

        const canSubmit = taxAmountPerSeat !== null &&
            !members.some(member => member.email.length > 0 && !emailRegex.test(member.email)) &&
            !members.some(member => members.some(otherMember => member.email && member.email.toLowerCase() === otherMember.email.toLowerCase() && member.id !== otherMember.id));

        return (
            <Container>
                <HeaderContainer>
                    <HeaderBackdrop />
                    <BeautifulLogoContainer>
                        <BeautifulLogo darkOnLight={!app.isDarkMode} forceMarketing width={132.855} />
                    </BeautifulLogoContainer>
                    <ProgressBarContainer>
                        <ProgressBar progress={100} prevProgress={80} />
                    </ProgressBarContainer>
                </HeaderContainer>

                {isSubmitting && <Spinner />}

                <ScrollContainer>
                    <ContentAlignerContainer>
                        <ContentContainer>
                            <WorkspaceSettingsContainer>
                                <StyleDialogTitle>
                                    <div>
                                        <Icon
                                            iconName="domain"
                                        />
                                        Create a Team
                                    </div>
                                </StyleDialogTitle>
                                <TeamNameContainer>
                                    <TeamNameField
                                        autoFocus
                                        onChange={this.handleChangeTeamName}
                                        variant="standard"
                                        name="Company Name"
                                        label="Company Name"
                                        error={errorCompanyNameMissing}
                                    />
                                </TeamNameContainer>
                                <div style={{ marginBottom: "20px" }}>
                                    <AddTeamMembers
                                        billingTerm={billingInterval}
                                        members={members}
                                        onUpdateMember={this.handleMemberUpdate}
                                        onAddMember={this.handleAddMember}
                                        onRemoveMember={this.handleRemoveMember}
                                        showLicenseDropdown={false}
                                        isSignUpB={true}
                                    />
                                </div>
                            </WorkspaceSettingsContainer>

                            <PaymentDetailsContainer>
                                {prices.month && (
                                    <>
                                        <SectionTitle>Choose plan</SectionTitle>
                                        <StyledSelect
                                            SelectDisplayProps={{ style: { textTransform: "capitalize" } }}
                                            value={billingInterval}
                                            inputProps={{ className: "select-input" }}
                                            onChange={event => {
                                                this.setState({ billingInterval: event.target.value });
                                            }}
                                            renderValue={value => {
                                                if (value === "year") {
                                                    return `Annual Plan - $${prices.year.unit_amount / 12 / 100} /month`;
                                                }
                                                return `Monthly Plan - $${prices.month.unit_amount / 100}/month`;
                                            }}
                                        >
                                            <MenuItem
                                                value="year"
                                            >
                                                Annual Plan - ${prices.year.unit_amount / 12 / 100}/month per Team Pro Seat
                                            </MenuItem>
                                            <MenuItem value="month">
                                                Monthly Plan - ${prices.month.unit_amount / 100}/month per Team Pro Seat
                                            </MenuItem>
                                        </StyledSelect >
                                    </>
                                )}

                                {
                                    !prices.month && (
                                        <>
                                            <b>{this.state.productDisplayName} - Annual Plan</b>
                                        </>
                                    )
                                }

                                <Gap10 />

                                <div>
                                    {price && price.billing_scheme === "tiered" && (
                                        <>
                                            <div style={{ fontSize: 16, display: "block", marginBottom: 15, color: "#666", lineHeight: "145%", letterSpacing: "-0.28px" }}>
                                                {this.props.quantity > 0 && (
                                                    <div>Includes first {this.props.quantity} seats.</div>
                                                )}
                                                {price.tiers.map((tier, i) => {
                                                    if (tier.up_to !== null) {
                                                        if (tier.up_to <= this.props.quantity ?? 0) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div style={{ lineHeight: 1.5 }}>
                                                                {i === 0 ? "First " + tier.up_to : "Next " + (tier.up_to - price.tiers[i - 1].up_to)} seats: {i === 0 ? "" : "+"}${tier.flat_amount / 100}/year
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div style={{ lineHeight: 1.5 }}>
                                                                Then: +${tier.unit_amount / 100}/year per seat
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <span style={{ fontSize: 16, display: "block", marginBottom: 15, color: "#666", lineHeight: "145%", letterSpacing: "-0.28px" }}>
                                                Your plan will cost <strong>${this.amount.toFixed(2)}/{billingInterval}</strong> and includes {this.numTieredSeats} seats.&nbsp;
                                                {<TermsOfUseAndRefundPolicy />}
                                            </span>
                                        </>
                                    )}
                                    {price?.billing_scheme !== "tiered" && (
                                        <span style={{ fontSize: 14, display: "block", marginBottom: 15, color: "#666" }}>
                                            You are adding a total of {seatCount} Pro team {"seat".pluralize(seatCount > 1)}, including your own, at <span style={{ fontWeight: 900 }}>${pricePerSeatWithTax}/{billingInterval}</span> per seat.&nbsp;
                                        </span>
                                    )}
                                </div>

                                <Gap30 />
                                {!successfulPurchase && (
                                    <PaymentFormB
                                        priceId={priceId}
                                        seatCount={seatCount}
                                        customerType="team"
                                        teamName={teamName}
                                        members={members.filter(member => member.email.length)}
                                        message={message}
                                        amount={this.amount}
                                        hasTakenTrial={hasTakenTeamTrial}
                                        onSuccess={this.handleSuccess}
                                        onBeforeSubmit={this.handleBeforeCharge}
                                        onFailure={this.handleFailure}
                                        submitLabel={hasTakenTeamTrial ? "Confirm" : "Start Free Trial"}
                                        submitButtonStyle={{ width: "100%" }}
                                        buttonsContainerStyle={{ marginTop: 12, justifyContent: "flex-start", marginBottom: 30 }}
                                        canSubmit={canSubmit}
                                        onPromoChanged={this.handlePromoChanged}
                                        onTaxCalculated={this.handleTaxCalculated}
                                        isSignUpB={true}
                                        titleStyle={{
                                            fontSize: "20px",
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            lineHeight: "120%",
                                            letterSpacing: "0.5px",
                                            color: "#222",
                                            marginBottom: "18px"
                                        }}
                                        errorMessage={errorCompanyNameMissing ? "Company name is required" : ""}
                                    />
                                )}
                                <span style={{ fontSize: 14, display: "block", color: "#777", marginTop: 15 }}>
                                    {!hasTakenTeamTrial && <>
                                        Once your 14 day free trial ends on <span style={{ fontWeight: 900 }}>{trialExpirationDate && trialExpirationDate.format("MMMM D, YYYY")}</span>,
                                        you will be charged <span style={{ fontWeight: 900 }}>${totalPriceWithTax}</span>.
                                    </>}
                                    {hasTakenTeamTrial && <>
                                        You will be charged <span style={{ fontWeight: 900 }}>${totalPriceWithTax}</span>.
                                    </>}
                                    &nbsp;Your subscription will <strong>automatically renew {billingInterval == "year" ? "annually" : "monthly"}</strong>.
                                    Update your billing information, add and remove seats or cancel your subscription at any time from your account management.&nbsp;
                                    {<TermsOfUseAndRefundPolicy />}
                                </span>
                                {!hasTakenTeamTrial && <div style={{ float: "left", color: "#6d6d6d", letterSpacing: "0.1px", marginTop: 15, fontSize: 14 }}>
                                    Interested in a custom trial? <a href="mailto:sales@beautiful.ai" style={{ color: "#11a9e2" }}>Contact sales</a>
                                </div>}
                            </PaymentDetailsContainer >
                        </ContentContainer>
                    </ContentAlignerContainer>
                </ScrollContainer>
            </Container>
        );
    }
}

export default withFirebaseUser(CreateWorkspaceDialogB);
